<template>
  <div class="blog-container">
    <h1>Hi, today is a great day</h1>
  </div>
</template>

<script>
export default {
  name: "SimpleBlog",
};
</script>

<style scoped>
.blog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  font-family: Arial, sans-serif;
}
</style>
